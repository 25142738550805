import {
  style,
  trigger,
  state,
  transition,
  animate,
} from "@angular/animations";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { APP_SETTINGS, CONFIG, CONFIG_CONSTANTS } from "src/app/config/config";
import { CustomMenuItem } from "src/app/core/models/menuItem";
import { AccountService } from "src/app/core/services/account.service";
import { AuthService } from "src/app/core/services/auth.service";
import { PermissionsService } from "src/app/core/services/permissions.service";
import { RouteStateService } from "src/app/core/services/route-state.service";
import { SessionService } from "src/app/core/services/session.service";
import { SharedService } from "src/app/core/services/shared.service";
import { ToastMessageService } from "src/app/core/services/toast-message.service";
import { UserInfoService } from "src/app/core/services/user-info.service";

@Component({
  selector: "app-admin-header",
  templateUrl: "./admin-header.component.html",
  styleUrls: ["./admin-header.component.css"],
  animations: [
    trigger("hamburguerX", [
      /*
        state hamburguer => is the regular 3 lines style.
        states topX, hide, and bottomX => used to style the X element
      */
      state("hamburguer", style({})),
      // style top bar to create the X
      state(
        "topX",
        style({
          transform: "rotate(45deg)",
          transformOrigin: "left",
          margin: "6px",
        })
      ),
      // hides element when create the X (used in the middle bar)
      state(
        "hide",
        style({
          opacity: 0,
        })
      ),
      // style bottom bar to create the X
      state(
        "bottomX",
        style({
          transform: "rotate(-45deg)",
          transformOrigin: "left",
          margin: "6px",
        })
      ),
      transition("* => *", [
        animate("0.2s"), // controls animation speed
      ]),
    ]),
  ],
})
export class AdminHeaderComponent implements OnInit, AfterViewInit {
  showInfo = false;
  logo: any;
  mobileLogo: any;
  tenantName: any = "";
  message: string = "";
  appName: string = CONFIG_CONSTANTS.project_name;
  userName: string = "";
  userLogo: string = "";
  roleName: string = "";
  isHamburguer: boolean = false;

  notificationCount: number = 2;
  navBarItems: any = [];
  isAdmin: boolean = false;
  timezone: string = "";
  modulePerms: string = "";
  fullName: any;
  daysLeft: any = 0;
  isFreeTrial: any;

  expDate: Date = new Date();
  currentDate: Date = new Date();
  wordsInNames: any[] = [];

  constructor(
    public authService: AuthService,
    private router: Router,
    public userInfoService: UserInfoService,
    private sessionService: SessionService,
    public routeStateService: RouteStateService,
    public permissionsService: PermissionsService,
    public sharedService: SharedService,
    private accountService: AccountService,
    private msalService: MsalService,
    private cdf: ChangeDetectorRef,
    public toastmessageService: ToastMessageService,
  ) {}

  ngAfterViewInit(): void {
    this.sharedService.getData().subscribe((data) => {
      if (data != "") {
        let wordsInNames = [];
        const words = data?.split("|");
        for (let i = 0; i < 2; i++) {
          wordsInNames.push(words[i]);
        }
        var fN = wordsInNames?.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        this.fullName = fN?.join(" ");
        this.userLogo =
          this.fullName.split(" ").shift().charAt(0) +
          this.fullName.split(" ").pop().charAt(0);
        this.timezone = words[2];
        this.cdf.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.logo = APP_SETTINGS.LOGO;
    this.mobileLogo = APP_SETTINGS.TRANSPARENT_LOGO;
    const logoRef: HTMLImageElement = document.querySelector("#logo");
    logoRef.style.height = APP_SETTINGS?.LOGO_ADMIN_SIZE || "20px";

    if (this.navBarItems.length < 1) {
      this.getPermissions();
    }
    this.getUserInfo();
  }

  getUserInfo() {
    let decodedTokenObj = this.authService.getTokenObjects();
    this.accountService.getAccountDetails(decodedTokenObj.email).subscribe({
      next: (apiResponse: any) => {
        if (apiResponse && apiResponse.data) {
          let data = apiResponse.data;
          this.expDate = new Date(data?.freeTrialExpiryDate);
          if (data?.isFreeTrial) {
            if (this.expDate) {
              const timeDifference =
                this.expDate.getTime() - this.currentDate.getTime();
              // Convert the difference to days
              this.daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            }
           
          }
          if(data?.isFreeTrial && this.daysLeft < 1) {
            this.toastmessageService.error("Your subscription plan has expired!");
            this.authService.logout();
          } else {
          //Configured for common object
          let tokenObj = {
            cms: data?.cms,
            name: data?.firstName + " " + data?.lastName,
            email: data?.emailId,
            fte: data?.freeTrialExpiryDate,
            ift: data?.isFreeTrial,
            tnm: data?.tenantName,
            rnm: data?.roleName,
            tzn: data?.timeZone,
            ndl: this.daysLeft,
          };
          localStorage.setItem("auth_info", JSON.stringify(tokenObj));

          this.getUserData();
        }
        }
      },
      error: (err) => {
        console.error("Error:", err.message);
      },
    });
  }

  getUserData() {
    if (Boolean(this.userInfoService.getTokenData("cms")) === true) {
      this.isAdmin = true;
    }
    this.tenantName = this.userInfoService.getTokenData("tnm");
    this.roleName = this.userInfoService.getTokenData("rnm");

    this.userName = this.userInfoService.getTokenData("name");
    const words = this.userName.split(" ");
    var fN = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    this.fullName = fN.join(" ");

    this.userLogo =
      this.userName.split(" ").shift().charAt(0) +
      this.userName.split(" ").pop().charAt(0);

    this.timezone = this.userInfoService.getTokenData("tzn");
    this.isFreeTrial = this.userInfoService.getTokenData("ift");

    this.daysLeft = this.userInfoService.getTokenData("ndl");
  }

  timeZoneAbbreviated = () => {
    const { 1: tz }: any = new Date().toString().match(/\((.+)\)/);
    if (tz.includes(" ")) {
      return tz
        .split(" ")
        .map(([first]: any) => first)
        .join("");
    } else {
      return tz;
    }
  };

  formatTimeZone(timezoneId) {
    try {
      // Get current date and time
      const now = new Date();

      // Create a new date formatter with the specified timezone ID
      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: timezoneId,
        timeZoneName: "short",
      });

      // Format the current date to get the timezone abbreviation
      const timeZoneName = formatter
        .formatToParts(now)
        .find((part) => part.type === "timeZoneName");

      return timeZoneName.value;
    } catch (error) {
      console.error("Error:", error.message);
      return null;
    }
  }

  getPermissions() {
    var obj = {
      tenantCode: this.userInfoService.getTokenData("tid"),
      appName: this.appName,
    };
    this.permissionsService
      .projectPermissionByProjName(obj)
      .subscribe((response) => {
        if (response) {
          if (response.data != null && response.data.length > 0) {
            this.navBarItems = response.data[0].childs;
            this.navBarItems.sort((n1, n2) => {
              if (n1.sortOrder > n2.sortOrder) {
                return 1;
              }
              if (n1.sortOrder < n2.sortOrder) {
                return -1;
              }
              return 0;
            });
          }
        }
      });
  }

  userInfo() {
    this.showInfo = !this.showInfo;
  }

  logout() {
    this.authService.logout();
  }
  onLogoClick() {
    if (
      APP_SETTINGS?.LOGO_ADMIN_LINK != null &&
      APP_SETTINGS?.LOGO_ADMIN_LINK != "" &&
      APP_SETTINGS?.LOGO_ADMIN_LINK != "#"
    ) {
      window.location.reload();
    }
  }

  redirectBlank(url: string) {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.click();
  }

  // on menu click event
  onMenuClick(menu: CustomMenuItem) {
    // if child are available then open child
    if (menu.childs != undefined || menu.childs != null) {
      // this.toggleSubMenu(menu);
      return;
    }
    if (
      menu.routerLink == undefined ||
      menu.routerLink == null ||
      menu.routerLink == ""
    ) {
      this.routeStateService.add("Error 404", "/error", null, false);
      return;
    }
    this.sessionService.setItem("active-menu", menu.label);
    this.routeStateService.add(menu.label, menu.routerLink, null, true);
    // hide menu bar after menu click for mobile layout
  }

  onClick() {
    this.isHamburguer = !this.isHamburguer;
  }

  trackByFn(index, item) {
    return index;
  }
  goCMS() {
    (window as any).open(CONFIG.CMS_Url, "_blank");
  }
}
