import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'site-layout',
  templateUrl: 'site-layout.component.html',
  styleUrls: ['site-layout.component.css']
})

export class SiteLayoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }

}