import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import 'rxjs/add/operator/filter';
import { Router } from '@angular/router';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['auth.component.css'],
})
export class AuthComponent implements OnInit {
  showLoader: boolean = true;

  constructor(private authService: AuthService,
    private router: Router) {}

  ngOnInit() {
    this.showLoader = false;

    let decodedToken = this.authService.getTokenObjects();    

    if (
      decodedToken != undefined &&
      decodedToken != null &&
      decodedToken.name != null &&
      decodedToken.name != undefined &&
      decodedToken.name != ''
    ) {
      this.router.navigateByUrl('/admin/docservice/docrequest-list');
    }
    else{
      this.authService.clearSessionWhenExpired();
    }
  }
}
