<footer class="footer">
  <div class="footer-contact-sender">
    <i class="fa fa-envelope-o" style="font-size: 30px; padding: 2px 20px 2px 0px"></i>
    <div class="contact-sender-data">
      Questions regarding the documents? <br />
      <b>Conatct sender </b>
    </div>
  </div>
  <div class="footer-button">
    <div class="support">
      <i class="fa fa-question-circle" style="font-size: 18px; padding: 2px 8px"></i><span>Support</span>
    </div>
    <div class="envelope-details">
      <i class="fa fa-info-circle" style="font-size: 18px; padding: 2px 8px"></i><span>Envelope Details</span>
    </div>
  </div>
</footer>