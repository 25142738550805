import { Component } from "@angular/core";
import { SpinnerService } from "src/app/core/services/spinner.service";
import { UserInfoService } from "src/app/core/services/user-info.service";

@Component({
  selector: "app-session-expiry",
  templateUrl: "./session-expiry.component.html",
  styleUrls: ["./session-expiry.component.css"],
})
export class SessionExpiryComponent {
  userName: string = "";
  constructor(
    public userInfoService: UserInfoService,
    public spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.userName = this.userInfoService.getTokenData("name");
    this.spinner.hide();
  }
}
