import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthService } from "../services/auth.service";
import { tap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has("BG-Authorization")) {
      const authHeaderValue = request.headers.get("BG-Authorization");
      if (authHeaderValue && authHeaderValue.startsWith("Bearer")) {
        const token = localStorage.getItem("client_token");
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
            headers: request.headers.delete("BG-Authorization"),
          });
        }
      }
      return next.handle(request).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
            }
          }
        )
      );
    } else {
      const token = localStorage.getItem("access_token");
      if (this.authService.isAuthenticated() && token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      return next.handle(request).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status == 401) {
                if (!this.authService.isAuthenticated()) {
                  this.router.navigate(["docservice/session-expired"]);
                } else {
                  this.router.navigate(["admin/docservice/forbidden"]);
                }
              } else {
                localStorage.clear();
                this.router.navigateByUrl("/login");
              }
            }
          }
        )
      );
    }
  }
}
